import {Component, Input, forwardRef, OnInit, Output, EventEmitter} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormControl} from '@angular/forms';
import {Languages, DefaultLanguage} from 'src/app/data/languages';
import {Localized} from '../../data/activity';

@Component({
  selector: 'app-multi-lang-input',
  templateUrl: './multi-lang-input.component.html',
  styleUrls: ['./multi-lang-input.component.scss'],
})
export class MultiLangInputComponent implements OnInit {
  public languages: Array<string>;
  public selectedLanguage: string;

  @Input() public label = '';
  @Input() public multiline = false;
  @Input() public minlength: number;
  @Input() public maxlength: number;
  @Input() public tags = false;
  @Input() isSchedule;

  @Input() public localizedName?: Localized;
  @Output() localizedNameChanged = new EventEmitter<Localized>();

  @Input() descriptionIndex?: number;
  @Input() public localizedDescription?: Localized;
  @Output() localizedDescriptionChanged = new EventEmitter<Localized>();

  constructor(public controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.languages = Languages;
    this.selectedLanguage = DefaultLanguage;

    // Ensure controls are created for all supported languages
    Languages.forEach((language) => {
      if (!this.controlContainer.control.get(language)) {
        (<FormGroup> this.controlContainer.control).addControl(language, new FormControl(''));
      }
    });
  }

  getValueFromLanguage(isName: boolean): string {
    return isName ? this.checkText(this.localizedName?.[this.selectedLanguage]) :
      this.checkText(this.localizedDescription?.[this.selectedLanguage]);
  }

  private checkText(text: string): string {
    if (text === undefined) {
      return '';
    }
    return text;
  }

  onNameChange(event: any): void {
    this.localizedName[this.selectedLanguage] = event.target.value;
    this.localizedNameChanged.emit(this.localizedName);
  }

  onDescriptionChange(event: any): void {
    this.localizedDescription[this.selectedLanguage] = event.target.value;
    this.localizedDescriptionChanged.emit(this.localizedDescription);
  }

  selectLanguage(language: string): void {
    this.selectedLanguage = language;
  }

}
