import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import {FormGroup, FormControl} from '@angular/forms';
import * as moment from 'moment';
import { Member } from 'src/app/data/member';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {ThemePalette} from '@angular/material/core';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CompanyService } from 'src/app/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  public members: Member[];
  private $members: Subscription;
  public isLoaded = false;
  public isLoading = false;
  public selectedValue;

  startDate = '';
  endDate = '';
  todayDay = 0;
  todayMonth = 0;
  todayYear = 0;
  today = '';

  public quickSelectors = ['history.0today', 'history.1yesterday', 'history.2last7days', 'history.3thismonth', 'history.4lastmonth', 'history.5thisyear', 'history.6lastyear'];
  
  private invalidDate = false;

  public range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  public quickSelectionForm = new FormGroup({
    select: new FormControl()
  });

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';

  constructor(private companyService: CompanyService, 
              private authService: AuthService,
              private _snackBar: MatSnackBar,
              private translate: TranslateService) {
    this.startDate = moment().subtract(30, 'days').format('YYYY-MM-DD').toString();
    this.endDate = moment().format('YYYY-MM-DD').toString();

    this.todayYear = Number(moment().format('YYYY').toString());
    this.todayMonth = Number(moment().format('MM').toString());
    this.todayDay = Number(moment().format('DD').toString());
    this.today = moment().format('YYYY-MM-DD').toString();

    this.loadData();
    this.authService.$selectedCompany.subscribe(_ => {
      this.isLoaded = false;
      this.members = [];
      this.loadData();
    });
  }

  ngOnInit(): void {
  }  

  loadData() {
    const isHistory = true;
    this.isLoading = true;
    this.isLoaded = false;
    if (this.$members) {
      this.$members.unsubscribe();
    }
    this.$members = this.companyService.getMembers(this.authService.currentProfile, true, this.startDate, this.endDate)
        .subscribe(members => {
          this.members = members;
          this.isLoading = false;
          this.isLoaded = true;
          this.$members.unsubscribe();
          this.$members = null;
      });
  }

  setStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.quickSelectionForm.reset();
    this.invalidDate = false;
    if (!event || !event.value) {
      this.invalidDate = true;
      return;
    }

    let year = event.value.getFullYear();
    let month = event.value.getMonth()+1;
    let day = event.value.getDate();

    if (this.isFutureDate(year, month, day)) {
      this.showPopUp();
      this.invalidDate = true;
      return;
    }
    let dayString = day.toString();
    let monthString = month.toString();
  
    if (day < 10) {
      dayString = '0' + dayString;
    }
    if (month < 10) {
      monthString = '0' + monthString;
    }
    this.startDate = year + '-' + monthString + '-' + dayString;
  }
  
  setEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    if (this.invalidDate) {
      return;
    }
    if (!event || !event.value) {
      this.endDate = this.today;
      this.loadData();
      return;
    }

    let year : String = (event.value.getFullYear()).toString();
    let month : String = (event.value.getMonth()+1).toString();
    let day : String = (event.value.getDate()).toString();
  
    if (day.length == 1) {
      day = '0' + day;
    }
    if (month.length == 1) {
      month = '0' + month;
    }
  
    this.endDate = year + '-' + month + '-' + day;
    this.loadData();
  }

  isFutureDate(year, month, day) {
    if (this.todayYear < year) {
      return true;
    }

    if (this.todayYear == year) {
      if (this.todayMonth < month) {
        return true;
      }

      if (this.todayMonth == month) {
        if (this.todayDay < day) {
          return true;
        }
      }
    }
    return false;
  }

  quickSelect(selection) {
    this.range.reset();
    switch(selection) {
      case 0: 
        this.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD').toString();
        this.endDate = this.today;
        break;
      case 1:
        this.startDate = moment().subtract(2, 'days').format('YYYY-MM-DD').toString();
        this.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD').toString();
        break;
      case 2:
        this.startDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD').toString();
        this.endDate = this.today;
        break;
      case 3:
        this.startDate = moment().format('YYYY-MM').toString();
        this.startDate = this.startDate + "-01";
        this.endDate = this.today;
        break;
      case 4:
        this.startDate = moment().subtract(1, 'months').format('YYYY-MM').toString();
        this.startDate = this.startDate + "-01";
        this.endDate = moment().format('YYYY-MM').toString();
        this.endDate = this.endDate + "-01";
        break;
      case 5:
        this.startDate = moment().format('YYYY').toString();
        this.startDate = this.startDate + "-01-01";
        this.endDate = this.today;
      break;
      case 6:
        this.startDate = moment().subtract(1, 'year').format('YYYY').toString();
        this.startDate = this.startDate + "-01-01";
        this.endDate = moment().subtract(1, 'year').format('YYYY').toString();
        this.endDate = this.endDate + "-12-31";
        break;
      default:
        this.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD').toString();
        this.endDate = this.today;
        break;
    }
    this.loadData();
  }

  showPopUp() {
      const newObserver = new ReplaySubject();
      let error = null;
      let close = null;

      this.translate.get('history.issuemessage').subscribe( (translation: string) => {
          error = translation;
          newObserver.next();
      });
  
      this.translate.get('history.issue').subscribe( (translation: string) => {
          close = translation;
          newObserver.next();
      });
  
      newObserver.subscribe( () => {
          if(error && close) {
              this._snackBar.open(error, close, { duration: 3000 });
          }
      });
  }
}
