<ng-container [formGroup]="controlContainer.control">
  <div class="multi-lang-control">
    <div class="row">
      <div class="col">
        <label>{{label}}</label>
      </div>
      <div class="btn-group col" role="group">
        <button type="button" class="btn"
                *ngFor="let language of languages" (click)="selectLanguage(language)"
                [ngClass]="language === selectedLanguage ? 'btn-primary' : 'btn-secondary'">{{ language }}</button>
      </div>
    </div>

    <div *ngIf="tags">
      <tag-input *ngFor="let language of languages" [formControlName]="language" placeholder="+таг"
                 secondaryPlaceholder="Внеси тагови" [class.hidden]="language !== selectedLanguage"></tag-input>
    </div>
    <div *ngIf="!tags">
      <div *ngIf="!multiline">
        <input type="text" *ngFor="let language of languages" [minlength]="minlength" [maxlength]="maxlength"
               class="form-control" [formControlName]="language" [class.hidden]="language !== selectedLanguage"
               [value]="getValueFromLanguage(true)" (blur)="onNameChange($event)">
      </div>
      <div *ngIf="multiline">
        <textarea *ngFor="let language of languages" [minlength]="minlength" [maxlength]="maxlength"
                  class="form-control" rows="5" [formControlName]="language"
                  [class.hidden]="language !== selectedLanguage"
                  [value]="getValueFromLanguage(false)"
                  (blur)="onDescriptionChange($event)">
        </textarea>
      </div>
    </div>
  </div>
</ng-container>
