import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Profile } from '../../data/profile';
import { throwError } from 'rxjs';
import { Role } from '../../data/role';
import { Company } from 'src/app/data/company';
import { Router } from '@angular/router';
import { CompanyService } from '../company/company.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //#region params
  public currentProfile: Profile;
  public $profile: Subject<Profile> = new Subject<Profile>();
  public $selectedCompany: Subject<Company> = new Subject<Company>();
  private CACHED_PROFILE_KEY = "currentProfile";
  private cachedProfile: Profile = JSON.parse(localStorage.getItem(this.CACHED_PROFILE_KEY));
  //#endregion

  //#region constructor
  constructor(private db: AngularFireDatabase,
              private afAuth: AngularFireAuth,
              private companyService: CompanyService) { }
  //#endregion

  //#region Firebase rxjs API calls and filters
  silentLogin() {
    
  }

  login(email: string, password: string, rememberMe = false): Observable<Profile> {
    return this.db.list('/profile', ref => ref.orderByChild('email').equalTo(email)).valueChanges().pipe(
      switchMap((profiles: Profile[]) => {
        if (profiles.length) {
          return this.afAuth.signInWithEmailAndPassword(email, password).then(credential => {
            if (!credential) {
              return null;
            }
            this.currentProfile = profiles[0];
            this.currentProfile.rememberMe = rememberMe;
            this.$profile.next(this.currentProfile);
            this.cacheProfile(this.currentProfile);
            return this.currentProfile;
          });
        } else {
          return throwError('not-admin');
        }
      })
    );
  }

  isUserManager(id: string, email: string): Observable<Boolean> {
    return this.getUserRoles(id, email).pipe(
      switchMap(roles => {
        return of(roles.length > 0);
      })
    )
  }

  getUserRoles(id: string, email: string): Observable<Role[]> {
    return this.db.list('/role', ref =>
      ref.orderByChild('role').equalTo('studio-manager')).valueChanges().pipe(
        switchMap((rolesFromDb: Role[]) => {
          const roles: Role[] = [];
          rolesFromDb.forEach(role => {
            if (role.userId == id && role.email == email && role.companyId) {
              role.isStudioManager = true;
              roles.push(role);
            }
          });
          if (roles.length) {
            return of(roles);
          } else {
            return throwError('not-manager')
          }
        })
      )
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }
  //#endregion

  //#region helper methods
  logout(router: Router) {
    this.cacheProfile(null);
    this.currentProfile = null;
    router.navigate(['login']);
  }

  isUserLoggedIn(): Boolean {
    if (this.currentProfile == null) {
      this.initCachedUser();
    }
    return this.currentProfile != null;
  }

  getName(): string {
    return this.currentProfile.name;
  }

  getFirstName(): string {
    return this.currentProfile.name.split(" ")[0];
  }

  getLastName(): string {
    return this.currentProfile.name.split(" ")[1];
  }

  getPhotoUrl(): string {
    if (this.currentProfile == null) {
      return "";
    }  
    return this.currentProfile.photoUrl;
  }

  private initCachedUser() {
    this.cachedProfile = JSON.parse(localStorage.getItem(this.CACHED_PROFILE_KEY));
    this.currentProfile = this.cachedProfile;
  }

  private cacheProfile(profile: Profile) {
    if (profile != null && !profile.rememberMe) {
      return;
    }
    localStorage.setItem(this.CACHED_PROFILE_KEY, JSON.stringify(profile));
    this.$profile.next(profile);
  }

  selectCompany(company: Company) {
    this.currentProfile.selectedCompany = company;
    this.cacheProfile(this.currentProfile);
    this.$selectedCompany.next(company);
  }

  getCompaniesByManager(): Observable<Company[]> {
    if (this.currentProfile == null) {
      return throwError("no-user");
    }
    return this.companyService.getCompanies(this.currentProfile.id, this.currentProfile.email, this);
  }

  getSelectedCompany(): Company {
    return this.currentProfile.selectedCompany;
  }
  //#endregion
}