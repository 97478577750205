import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-forgot-password-result-dialog',
  templateUrl: './forgot-password-result-dialog.component.html',
  styleUrls: ['./forgot-password-result-dialog.component.scss']
})
export class ForgotPasswordResultDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ForgotPasswordResultDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ForgotPasswordResultDialogData) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }
}
export interface ForgotPasswordResultDialogData {
  isValid: Boolean
}