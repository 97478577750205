import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { ForgotPasswordResultDialogComponent } from '../forgot-password-result-dialog/forgot-password-result-dialog.component';
import { TranslateService } from '@ngx-translate/core'; 

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public userGroup: FormGroup;
  public isSubmitted = false;
  public wrongInputs = false;
  public userInputError = false;

  constructor(private authService: AuthService,
              private router: Router,
              private forgotPassDialog: MatDialog,
              private forgotPassResultDialog: MatDialog,
              private translateService: TranslateService) { 
                this.translateService.setDefaultLang('mk');
              }

  ngOnInit(): void {
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate(['home']);
    }
    this.userGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      rememberMe: new FormControl('')
    });
  }

  submit() {
    const email = this.userGroup.value.email;
    const password = this.userGroup.value.password;
    const rememberMe = this.userGroup.value.rememberMe;
    this.authService.login(email, password, rememberMe).subscribe(profile => {
      if (profile) {
        // console.log("User exists. Check if it's manager");
        this.authService.isUserManager(profile.id, email).subscribe(isManager => {
          if (isManager) {
            this.router.navigate(['home']);
          } else {
            //console.log("User is NOT a manager");
            this.userInputError = true;
          }
        })
      } else {
        //console.log("User credentials invalid");
        this.userInputError = true;
      }
    }, error => {
      //console.log("User doesn't exist");
      this.userInputError = true;
    });
  }

  resetPassword() {
    console.log("reset pass called");
    const dialogRef = this.forgotPassDialog.open(ForgotPasswordDialogComponent, {
      maxWidth: '500px',
      data: { email: this.userGroup.value.email }
    });
    dialogRef.afterClosed().subscribe(email => {
      if (email && this.isValidEmail(email)) {
        this.authService.resetPassword(email).then(result => {
          console.log("Reset password success.");
          this.forgotPassResultDialog.open(ForgotPasswordResultDialogComponent, {
            maxWidth: '500px',
            data: { isValid: true }
          });
        }).catch(error => {
          console.log("Reset password error: " + error);
          this.forgotPassResultDialog.open(ForgotPasswordResultDialogComponent, {
            maxWidth: '500px',
            data: { isValid: false }
          });
        });
      }
    });
  }

  private isValidEmail(email: string): Boolean {
    const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regex.test(email);
  }
}
