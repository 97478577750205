export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBeYsIusREhHQfNBNEnTxDkyDsHS2xH_jY',
    authDomain: 'fitkit-staging.firebaseapp.com',
    databaseURL: 'https://fitkit-staging.firebaseio.com',
    storageBucket: 'fitkit-staging.appspot.com',
    messagingSenderId: '218150982373',
    universalUrl: 'https://go.staging.fitkit.app',
  },
};