import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { HistoryComponent } from './components/history/history.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FinanceComponent } from './components/finance/finance.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { MembershipsComponent } from './components/memberships/memberships.component';
import { ScanComponent } from './components/scan/scan.component';
import { HelpComponent } from './components/help/help.component';
import { ActivityFormComponent } from './components/activity-form/activity-form.component';

const routes: Routes = [
  { path: '', component: MainNavComponent, children: [
    { path: '', redirectTo: '/scan', pathMatch: 'full' },
    { path: 'history', component: HistoryComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'finance', component: FinanceComponent },
    { path: 'activities', component: ActivitiesComponent },
    { path: 'memberships', component: MembershipsComponent },
    { path: 'scan', component: ScanComponent },
    { path: 'help', component: HelpComponent},
    { path: 'activity/edit', component: ActivityFormComponent},
    { path: 'activity/create', component: ActivityFormComponent}
  ]},
  { path: 'login', component: LoginPageComponent },
  { path: 'home', component: HomePageComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    [ RouterModule.forRoot(routes) ]
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }