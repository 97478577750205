<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false"
      >
    <mat-toolbar style="border: none;"><a href="#" class="navbar-brand left-position"><img src="../../../assets/images/logo.png" alt="Loading..." style="height: auto; width: 50px;"/></a></mat-toolbar>
    <mat-nav-list>
      <div class="avatar-info" style="cursor: pointer;" (click)="goToHome()">
        <img *ngIf="authService.getPhotoUrl()" 
             class="avatar" 
             styleClass="p-mr-2" 
             size="large" 
             alt="Avatar" 
             src="{{authService.getPhotoUrl()}}" 
             shape="circle" />
        <p-avatar styleClass="p-mr-2" 
                  *ngIf="!authService.getPhotoUrl()" 
                  [style]="{'background-color':'#00D4D4', 'color': '#ffffff', 'margin-right': '10px', 'margin-bottom': '16px', 'width': '100px', 'height': '100px', 'font-size': '48px'}" 
                  shape="circle">{{authService.getName()[0]}}</p-avatar>
        <p class="full-name">{{authService.getName()}}</p>
        <p class="company">{{authService.getSelectedCompany().name}}</p>
      </div>

      <mat-divider></mat-divider>
      <a mat-list-item routerLink="dashboard" routerLinkActive="active-link"><mat-icon>dashboard_outline</mat-icon> 
        <span class="text">{{'navbar.dashboard' | translate}}</span>
      </a>
      <a mat-list-item routerLink="scan" routerLinkActive="active-link"><mat-icon>search</mat-icon> 
        <span class="text">{{'navbar.scancode' | translate}}</span>
      </a>
      <a mat-list-item routerLink="activities" routerLinkActive="active-link"><mat-icon>money</mat-icon> 
        <span class="text">{{'navbar.activities' | translate}}</span>
      </a>
      <a mat-list-item routerLink="history" routerLinkActive="active-link"><mat-icon>people_outline</mat-icon>
        <span class="text">{{'navbar.history' | translate}}</span> 
      </a>
      <a mat-list-item routerLink="memberships" routerLinkActive="active-link"> <mat-icon>perm_identity</mat-icon>
        <span class="text">{{'navbar.memberships' | translate}}</span>
      </a>
      <a mat-list-item routerLink="finance" routerLinkActive="active-link"><mat-icon> show_chart</mat-icon> 
        <span class="text">{{'navbar.finance' | translate}}</span>
      </a>
      <mat-divider></mat-divider>
      <!-- <span>Support</span> -->
      <a mat-list-item routerLink="help" routerLinkActive="active-link"><mat-icon>help_outline</mat-icon>
        <span class="text">{{'navbar.help' | translate}}</span>
      </a>
      <a mat-list-item class="link" (click)="logout()">{{'navbar.logout' | translate}}</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="header">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="dropdown-parent" *ngIf="companies">
        <p-dropdown [options]="companies" 
                    [(ngModel)]="selectedCompany"
                    placeholder="{{selectedCompany.name}}"
                    optionLabel="name"
                    (onChange)="onCompanyChange($event)"></p-dropdown>
      </div>
      <div class="col positioned-right">
        <mat-icon class="logout-icon" (click)="logout()" style="cursor: pointer;">exit_to_app</mat-icon>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
