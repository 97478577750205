<div class="container mt-5">
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <form [formGroup]="newActivity" #activityForm novalidate>
        <h2
          *ngIf="!activityId">{{ 'activity.create_activity' | translate }} {{(company | async)?.name_localized[selectedLanguage]}}
          ({{(company | async)?.address[0].addressDescription}})</h2>
        <h2 *ngIf="activityId">{{newActivity.value.name_localized[selectedLanguage]}}
          - {{(company | async)?.name_localized[selectedLanguage]}}
          ({{(company | async)?.address[0].addressDescription}})</h2>

        <div class="form-group"
             [ngClass]="{ 'has-error': newActivity.get('name_localized').touched && !newActivity.get('name_localized').valid }">
          <app-multi-lang-input label="{{ 'activity.name' | translate }}"
                                formGroupName="name_localized"
                                [localizedName]="localizedName"
                                (localizedNameChanged)="localizedNameChanged($event)"
                                [isSchedule]="false"></app-multi-lang-input>
          <div
            *ngIf="newActivity.get('name_localized').touched && newActivity.get('name_localized').hasError('required')"
            class="help-block">{{ 'activity.error_enter_name' | translate }}
          </div>
        </div>
        <div class="form-group"
             [ngClass]="{ 'has-error': newActivity.get('description_localized').touched && !newActivity.get('description_localized').valid }">
          <app-multi-lang-input label="{{ 'activity.description' | translate }}"
                                formGroupName="description_localized"
                                [localizedDescription]="localizedDescription"
                                (localizedDescriptionChanged)="localizedDescriptionChanged($event)"
                                multiline="true"
                                [isSchedule]="false"></app-multi-lang-input>
          <div
            *ngIf="newActivity.get('description_localized').touched && newActivity.get('description_localized').hasError('required')"
            class="help-block">{{ 'activity.error_enter_description' | translate }}
          </div>
        </div>
        <div class="form-group"
             [ngClass]="{ 'has-error': newActivity.get('cost').touched && !newActivity.get('cost').valid }">
          <label for="inputCost">{{ 'activity.credits' | translate }}</label>
          <input type="number" class="form-control" formControlName="cost" id="inputCost">
          <div *ngIf="newActivity.get('cost').touched && newActivity.get('cost').hasError('one')" class="help-block">
            {{ 'activity.error_enter_description' | translate }}
          </div>
        </div>
        <div class="form-group"
             [ngClass]="{ 'has-error': newActivity.get('externalUrl').touched && !newActivity.get('externalUrl').valid }">
          <label for="inputExternalUrl"> {{ 'activity.external_link' | translate }}</label>
          <input type="url" class="form-control" formControlName="externalUrl" id="inputExternalUrl"
                 [attr.disabled]="newActivity.get('onlineMeetingUrl').value || newActivity.get('confirmAttendanceUrl').value ? '' : null">
          <div *ngIf="newActivity.get('externalUrl').touched && newActivity.get('externalUrl').hasError('one')"
               class="help-block">{{ 'activity.error_enter_address' | translate }}
          </div>
        </div>
        <div class="form-group"
             [ngClass]="{ 'has-error': newActivity.get('onlineMeetingUrl').touched && !newActivity.get('onlineMeetingUrl').valid }">
          <label for="inputOnlineMeetingUrl">Online Class</label>
          <input type="url" class="form-control" formControlName="onlineMeetingUrl" id="inputOnlineMeetingUrl"
                 [attr.disabled]="newActivity.get('externalUrl').value || newActivity.get('confirmAttendanceUrl').value ? '' : null">
          <div
            *ngIf="newActivity.get('onlineMeetingUrl').touched && newActivity.get('onlineMeetingUrl').hasError('one')"
            class="help-block">{{ 'activity.error_online_class' | translate }}
          </div>
        </div>
        <div class="form-group"
             [ngClass]="{ 'has-error': newActivity.get('confirmAttendanceUrl').touched && !newActivity.get('confirmAttendanceUrl').valid }">
          <label for="inputConfirmAttendanceUrl">{{ 'activity.presence' | translate }}</label>
          <input type="url" class="form-control" formControlName="confirmAttendanceUrl" id="inputConfirmAttendanceUrl"
                 [attr.disabled]="newActivity.get('externalUrl').value || newActivity.get('onlineMeetingUrl').value ? '' : null">
          <div
            *ngIf="newActivity.get('confirmAttendanceUrl').touched && newActivity.get('confirmAttendanceUrl').hasError('one')"
            class="help-block">{{ 'activity.error_presence' | translate }}
          </div>
        </div>
        <div class="form-group invisible">
          <label for="universalUrl">Universal URL</label>
          <div class="url-container">
            <input type="url" class="form-control" formControlName="universalUrl" readonly #userinput>
            <button type="button" class="btn btn-primary" (click)="copyToClipboard(userinput)">
              <span class="fa fa-paste"></span>
            </button>
          </div>
        </div>
      </form>

      <div class="form-group" [ngClass]="{ 'has-error': isSubmitted && !currentCategory }">
        <label for="single-button">{{ 'activity.select_category' | translate }}</label>
        <div class="btn-group" ngbDropdown>
          <button *ngIf="currentCategory" id="single-button" type="button"
                  class="btn btn-primary">{{currentCategory.category.name_localized[selectedLanguage] ? currentCategory.category.name_localized[selectedLanguage] :
            currentCategory.category.name}}</button>
          <button *ngIf="!currentCategory" id="single-button" type="button" class="btn btn-primary">...</button>
          <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
            <span class="caret"></span>
          </button>
          <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="single-button">
            <li *ngFor="let category of categoryList | async" role="menuitem" class="category-item">
              <a ngbDropdownItem style="background-color: white;"
                 (click)="setCategory(category)">{{category.category.name_localized[selectedLanguage] ? category.category.name_localized[selectedLanguage] :
                category.category.name}}</a>
            </li>
          </ul>
        </div>
        <div *ngIf="isSubmitted && !currentCategory"
             class="help-block text-danger">{{ 'activity.error_category' | translate }}</div>
      </div>

      <!-- Facilities -->
      <div class="form-group" [ngClass]="{ 'has-error': isSubmitted && !currentCategory }">
        <label for="single-button">{{ 'activity.select_facilities' | translate }}</label>
        <div class="btn-group" ngbDropdown [autoClose]="'outside'">
          <button *ngIf="facilities.length > 0" id="single-button" type="button" class="btn btn-primary">
            {{ 'activity.facilities' | translate }}
          </button>
          <button *ngIf="facilities.length === 0" id="single-button" type="button" class="btn btn-primary">...</button>
          <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
            <span class="caret"></span>
          </button>
          <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="single-button">
            <li *ngFor="let facility of facilities; let i = index" role="menuitem" class="facility-item">
              <a ngbDropdownItem
                 style="background-color: white;"
                 [ngClass]="facility.enabled ? 'checkmark' : ''"
                 (click)="facility.enabled = !facility.enabled">
                {{facility.name_localized[selectedLanguage]}}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Amenities -->
      <div class="form-group" [ngClass]="{ 'has-error': isSubmitted && !currentCategory }">
        <label for="single-button">{{ 'activity.select_amenities' | translate }}</label>
        <div class="btn-group" ngbDropdown [autoClose]="'outside'">
          <button *ngIf="amenities.length > 0" id="single-button" type="button" class="btn btn-primary">
            {{ 'activity.amenities' | translate }}
          </button>
          <button *ngIf="amenities.length === 0" id="single-button" type="button" class="btn btn-primary">...</button>
          <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
            <span class="caret"></span>
          </button>
          <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="single-button">
            <li *ngFor="let amenity of amenities; let i = index" role="menuitem" class="amenity-item">
              <a ngbDropdownItem
                 style="background-color: white;"
                 [ngClass]="amenity.enabled ? 'checkmark' : ''"
                 (click)="amenity.enabled = !amenity.enabled">
                {{amenity.name_localized[selectedLanguage]}}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Gender availability -->
      <div class="form-group" [ngClass]="{ 'has-error': isSubmitted && !currentCategory }">
        <label for="single-button">{{ 'activity.gender.gender_restriction' | translate }}</label>
        <div class="btn-group" ngbDropdown>
          <button *ngIf="currentCategory" id="single-button" type="button"
                  class="btn btn-primary">{{ 'activity.gender.' + selectedGender | translate }}</button>
          <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
            <span class="caret"></span>
          </button>
          <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="single-button">
            <li *ngFor="let gender of genderList" role="menuitem" class="category-item">
              <a ngbDropdownItem style="background-color: white;"
                 (click)="selectGender(gender)">{{ 'activity.gender.' + gender | translate }}</a>
            </li>
          </ul>
        </div>
        <div *ngIf="isSubmitted && !currentCategory"
             class="help-block text-danger">{{ 'activity.error_category' | translate }}</div>
      </div>

      <div class="form-group" [ngClass]="{ 'has-error': isSubmitted && newImg && !data.image }">
        <label for="inputLogo">{{ 'activity.photo' | translate }}</label>
        <div>
          <label class="btn btn-primary btn-file">
            {{ 'activity.new_photo' | translate }} <input type="file" style="display: none;"
                                                          (change)="fileChangeListener($event)">
          </label>
        </div>
        <div class="photo-container">
          <img [src]="imgSrc" class="static-img">
          <p *ngIf="isSubmitted && newImg && !data.image"
             class="text-danger"> {{ 'activity.choose_photo' | translate }}</p>
        </div>
      </div>

      <div class="button-group">
        <button *ngIf="!activityId" type="submit" class="btn btn-primary" [disabled]="newActivity.invalid"
                (click)="submit()">{{ 'activity.create' | translate }}
        </button>
        <button *ngIf="activityId" type="submit" class="btn btn-primary" [disabled]="newActivity.invalid"
                (click)="save()">{{ 'activity.save' | translate }}
        </button>
        <button type="submit" class="btn btn-primary" (click)="cancel()">{{ 'activity.cancel' | translate }}</button>
      </div>
    </div>

    <div class="col-md-6 col-xs-12">
      <form [formGroup]="scheduleGroup">
        <div>
          <h3 class="pull-left">{{ 'activity.schedule' | translate }}</h3>
          <a role="button" class="pull-right" id="addSchedule" (click)="addSchedule()"><span class="fa fa-plus"></span></a>
          <div class="clearfix"></div>
        </div>

        <div formArrayName="scheduleItem" id="schedule">
          <div *ngFor="let item of getScheduleItem(); let i = index;" class="panel panel-primary">
            <div [formGroupName]="i">
              <div class="panel-heading">
                <div class="btn-group" ngbDropdown>
                  <button id="single-button" type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle>
                    {{days[scheduleGroup.get('scheduleItem').at(i).get('day').value] | titlecase}}
                    <span class="pull-right"> <span class="caret"></span></span>
                  </button>
                  <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="single-button">
                    <li *ngFor="let day of days; let j = index" role="menuitem">
                      <a ngbDropdownItem (click)="setDay(i, j)">{{day | titlecase}}</a></li>
                  </ul>
                </div>
                <span class="remove-schedule">
                      <i class="fa fa-copy fa-2x copy-day"
                         (click)="copySchedule(i)"></i>
                      <i class="fa fa-remove fa-2x"
                         (click)="deleteSchedule(i)"></i>
                  </span>
              </div>

              <div class="panel-body">
                <input *ngIf="false" type="text" class="form-control" formControlName="id">
                <div class="row">
                  <div class="form-group col-xs-4"
                       [ngClass]="{ 'has-error': isSubmitted && item.get('capacity').hasError('zero') }">
                    <label>{{ 'activity.capacity' | translate }}</label>
                    <input type="number" class="form-control" formControlName="capacity">
                  </div>
                  <div class="form-group col-xs-4"
                       [ngClass]="{ 'has-error': isSubmitted && item.hasError('compareTime') }">
                    <label>{{ 'activity.start' | translate }}</label>
                    <input type="time" class="form-control" formControlName="startTime">
                  </div>
                  <div class="form-group col-xs-4"
                       [ngClass]="{ 'has-error': isSubmitted && item.hasError('compareTime') }">
                    <label>{{ 'activity.end_session' | translate }}</label>
                    <input type="time" class="form-control" formControlName="endTime">
                  </div>
                  <p *ngIf="isSubmitted && item.get('capacity').hasError('zero')" class="col-xs-12 text-danger">
                    {{ 'activity.error_capacity' | translate }}</p>
                  <p *ngIf="isSubmitted && item.hasError('compareTime')"
                     class="col-xs-12 text-danger">{{ 'activity.error_invalid_session' | translate }}</p>
                </div>
                <div class="row">
                  <div class="form-group col-xs-6"
                       [ngClass]="{ 'has-error': isSubmitted && item.hasError('compareDate') }">
                    <label>{{ 'activity.valid_from' | translate}}</label>
                    <input type="date" class="form-control" formControlName="availableFrom">
                  </div>
                  <div class="form-group col-xs-6"
                       [ngClass]="{ 'has-error': isSubmitted && item.hasError('compareDate') }">
                    <label>{{ 'activity.valid_until' | translate}}</label>
                    <input type="date" class="form-control" formControlName="availableTo">
                  </div>
                  <p *ngIf="isSubmitted && item.hasError('compareDate')"
                     class="col-xs-12 text-danger">{{ 'activity.error_invalid_dates' | translate }}</p>
                </div>
                <div class="row">
                  <div class="form-group col-xs-12 form-full-width" [ngClass]="{ 'has-error': isSubmitted }">
                    <app-multi-lang-input label="{{'activity.description' | translate }}"
                                          formGroupName="description_schedule_localized"
                                          [localizedDescription]="localizedDescriptionSchedule[i]"
                                          (localizedDescriptionChanged)="localizedDescriptionScheduleChanged($event, i)"
                                          [descriptionIndex]="i"
                                          multiline="true"
                                          [isSchedule]="true"
                    ></app-multi-lang-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
