import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { Company } from 'src/app/data/company';
import { TranslateService } from '@ngx-translate/core'; 

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {

  public isHandset$: Observable<boolean>;
  public companies: Company[];
  public selectedCompany: Company;
  private companies$: Subscription;

  supportLanguages = [ 'mk', 'en'];
  constructor(private breakpointObserver: BreakpointObserver,
              private router: Router,
              public authService: AuthService,
              private translateService: TranslateService) {
                this.companies$ = this.authService.getCompaniesByManager().subscribe(companies => {
                  this.selectedCompany = this.authService.getSelectedCompany();
                  this.companies = companies;
                  this.companies$.unsubscribe();
                });
                this.translateService.addLangs(this.supportLanguages);
                this.translateService.setDefaultLang('mk');
  }

  ngOnInit() {
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  }

  selectLang(lang: string){
    this.translateService.use(lang);
  }

  logout() {
    this.authService.logout(this.router);
  }

  onCompanyChange(event: any) {
    this.authService.selectCompany(event.value as Company);
  }

  goToHome() {
    this.router.navigate(['home']);
  }
}
