import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
@Injectable()
export class FileService {

  storageRef: firebase.default.storage.Storage;

  constructor() {
    this.storageRef = firebase.default.storage();
  }

  upload(path: string, file: string = null): Promise<any> {
    return <Promise<any>>this.storageRef.ref(path).putString(file, 'data_url').then(snapshot => {
      console.log("Logo uploaded");
      return snapshot.ref.getDownloadURL();
    });
  }

  download(path: string): Promise<string> {
    return <Promise<string>>this.storageRef.ref(path).getDownloadURL().then(url => {
      return url;
    }, error => {
      switch ((<any>error).code) {
        case 'storage/object_not_found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }

      return "";
    });
  }

  delete(path): Promise<any> {
    return this.storageRef.ref(path).delete().then(_ => {
      console.log("File delete successful");
    }).catch(error => {
      console.log(error);
      switch ((<any>error).code) {
        case 'storage/object_not_found':
          // File doesn't exist
          console.log("File doesnt exist");
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          console.log("User not authorized for this action");
          break;
        case 'storage/canceled':
          // User canceled the upload
          console.log("Action canceled");
          break;
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          console.log("Unknown delete error");
          break;
      }

      // return Promise.reject("Delete file failed");
    });
  }
}
