import {Company} from './company';

export interface Activity {
  categoryId: string;
  cityId: string;
  companyId: string;
  cost: number;
  description: string;
  description_localized?: Localized;
  id?: string;
  name: string;
  name_localized?: Localized;
  photoUrl?: string;
  externalUrl?: string;
  onlineMeetingUrl?: string;
  confirmAttendanceUrl?: string;
  photo?: string;
  updatedAt?: string;
  facilities?: FacilityAmenityItem;
  amenities?: FacilityAmenityItem;
  genderAvailability: string;
}

export class UIActivity {
  public activity: Activity;
  public company: Company;

  constructor(activity: Activity) {
    this.activity = activity;
  }
}

export interface FacilityAmenityItem {
  [key: string]: true;
}

export interface Localized {
  en: string;
  mk: string;
  sq: string;
}

export const Gender = {
  all: 'all',
  male: 'male',
  female: 'female'
};
