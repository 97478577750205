import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {FileUploaderComponent} from '../file-uploader/file-uploader.component';
import {Observable, of, Subscription} from 'rxjs';
import {Company} from 'src/app/data/company';
import {Activity, FacilityAmenityItem, Gender, Localized} from 'src/app/data/activity';
import {environment} from 'src/environments/environment';
import {DefaultLanguage, Languages} from 'src/app/data/languages';
import {Schedule} from 'src/app/data/schedule';
import {CompanyService} from 'src/app/services/company/company.service';
import {ActivityService} from 'src/app/services/activity/activity.service';
import {AuthService} from 'src/app/services/auth/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Router} from '@angular/router';
import {Facility} from '../../data/facility';
import {Amenity} from '../../data/amenity';
import {mergeMap, switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

function compareTime(controlA: string, controlB: string, error: string, group: FormGroup) {
  const valueA = group.controls[controlA].value;
  const valueB = group.controls[controlB].value;

  if (valueA >= valueB) {
    const errors = {};
    errors[error] = true;
    return errors;
  }

  return null;
}

export class CategoryFull {
  category: any;
  activityNumber: number;
}


@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.scss'],
})
export class ActivityFormComponent implements OnInit, OnDestroy {

  @ViewChild(FileUploaderComponent) uploader: FileUploaderComponent;

  data: any;
  imgSrc = '';
  newImg = false;

  public days = ['понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота', 'недела'];

  public categoryList: Observable<CategoryFull[]>;
  public scheduleList = new Array<Schedule>();

  public newActivity: FormGroup;
  public currentCategory: CategoryFull;
  public scheduleGroup: FormGroup;

  public isSubmitted: boolean;
  public companyId: string;
  public company: Observable<Company>;
  public activityId: string;

  private subscriptions: Subscription;
  public facilities = new Array<Facility>();
  public amenities = new Array<Amenity>();

  localizedName: Localized;
  localizedDescription: Localized;
  localizedDescriptionSchedule = new Array<Localized>();

  genderList = [Gender.all, Gender.male, Gender.female];
  selectedGender = Gender.all;
  public selectedLanguage: string;

  constructor(
    private activityService: ActivityService,
    private companyService: CompanyService,
    private authService: AuthService,
    private location: Location,
    private fb: FormBuilder,
    private confirmDeleteDialog: MatDialog,
    private router: Router,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang;
    this.data = {};

    this.subscriptions = new Subscription();
    this.newActivity = new FormGroup(
      {
        name_localized: this.fb.group({
          [DefaultLanguage]: ['', Validators.required],
        }),
        description_localized: this.fb.group({
          [DefaultLanguage]: ['', Validators.required],
        }),
        cost: new FormControl(1, (control: FormControl) => control.value < 1 ? {one: true} : null),
        externalUrl: this.fb.control('', []),
        onlineMeetingUrl: this.fb.control('', []),
        confirmAttendanceUrl: this.fb.control('', []),
        universalUrl: this.fb.control('', []),
      }
    );

    this.scheduleGroup = this.fb.group({
      scheduleItem: this.fb.array([]),
    });

    this.initActivity();
    this.initSchedule();
    this.initFacilitiesAmenities();
  }

  initActivity(): void {
    const isCreate = this.router.url == '/activity/create';
    const activity = isCreate ? null : this.activityService.getSelectedActivity();
    this.categoryList = this.activityService.getCategoryList();
    this.companyId = this.authService.getSelectedCompany().id;
    if (!isCreate && this.activityService.getSelectedActivity()) {
      this.activityId = this.activityService.getSelectedActivity().activity.id;
    }

    if (this.activityId) {
      const universalUrl = environment.firebase.universalUrl;
      this.newActivity.get('universalUrl').setValue(`${universalUrl}/activity-details/${this.activityId}`);
      this.newActivity.patchValue(activity.activity);
      this.companyId = activity.activity.companyId;
      this.company = of(this.authService.getSelectedCompany());
      this.subscriptions.add(this.activityService.getActivity(this.activityId).subscribe(value => {
        this.currentCategory = value;
        this.selectedGender = value.activity.genderAvailability;
      }));
      this.subscriptions.add(this.activityService.getActivityPhoto(this.activityId).subscribe(url => {
        this.imgSrc = url;
        this.newImg = false;
      }));
    } else {
      this.company = this.companyService.getCompany(this.companyId);
      this.imgSrc = '';
      this.newImg = true;
    }

    this.localizedName = activity ? activity.activity.name_localized : this.newActivity.get('name_localized').value;
    this.localizedDescription = activity ? activity.activity.description_localized : this.newActivity.get('description_localized').value;
  }

  private getFacilitiesObservable(): Observable<Facility[]> {
    return this.activityId ? this.activityService.getActivity(this.activityId).pipe(
      switchMap((activity: Activity) => {
        return this.activityService.getFacilityList(activity);
      })) : this.activityService.getFacilityList(null);
  }

  private getAmenitiesObservable(): Observable<Amenity[]> {
    return this.activityId ? this.activityService.getActivity(this.activityId).pipe(
      switchMap((activity: Activity) => {
        return this.activityService.getAmenityList(activity);
      })) : this.activityService.getAmenityList(null);
  }

  initFacilitiesAmenities(): void {
    this.subscriptions.add(this.getFacilitiesObservable()
      .pipe(mergeMap(facilities => {
        this.facilities = facilities;
        return this.getAmenitiesObservable();
      }))
      .subscribe(amenities => {
        this.amenities = amenities;
      }));
  }

  initSchedule(): void {
    this.subscriptions.add(this.activityService.getScheduleList(this.activityId).subscribe(allSchedules => {
      allSchedules.forEach((schedule, index) => {
        const current = this.scheduleList.find(item => {
          return item.id === schedule.id;
        });
        if (current === undefined) {
          this.scheduleList.push(schedule);
          this.addSchedule(schedule);
        }
      });
    }));
  }

  initScheduleItem(schedule: Schedule = null): FormGroup {
    const date = moment().format('YYYY-MM-DD').toString();
    return this.fb.group({
      id: schedule?.id,
      day: schedule == null ? [0] : schedule.day,
      capacity: schedule == null ?
        [1, (control: FormControl) => control.value <= 0 ? {zero: true} : null]
        : schedule.capacity,
      startTime: schedule == null ? ['12:00'] : schedule.startTime,
      endTime: schedule == null ? ['13:00'] : schedule.endTime,
      availableFrom: schedule == null ? [date] : schedule.availableFrom,
      availableTo: schedule == null ? [date] : schedule.availableTo,
      description: schedule == null ? [''] : schedule.description,
      description_schedule_localized: this.fb.group({
        ['mk']: schedule == null ? ['']
          : schedule.description_localized.mk,
        ['en']: schedule == null ? ['']
          : schedule.description_localized.en,
        ['sq']: schedule == null ? ['']
          : schedule.description_localized.sq,
      }),
    }, {
      validator: Validators.compose([
        compareTime.bind(this, 'startTime', 'endTime', 'compareTime'),
        compareTime.bind(this, 'availableFrom', 'availableTo', 'compareDate'),
      ])
    });
  }


  getSchedule(): any[] {
    const allSchedule = [];
    for (let i = 0; i < (<FormArray> this.scheduleGroup.controls['scheduleItem']).length; i++) {
      const control = (<FormGroup> this.scheduleGroup.controls['scheduleItem']).controls[i];
      const schedule: Schedule = {
        id: control.get('id').value,
        day: control.get('day').value,
        capacity: control.get('capacity').value,
        startTime: control.get('startTime').value,
        endTime: control.get('endTime').value,
        availableFrom: control.get('availableFrom').value,
        availableTo: control.get('availableTo').value,
        description: this.localizedDescriptionSchedule[i][DefaultLanguage],
        description_localized: this.localizedDescriptionSchedule[i],
      };

      allSchedule.push(schedule);
    }

    return allSchedule;
  }

  addSchedule(value: Schedule = null): void {
    const control = <FormArray> this.scheduleGroup.controls['scheduleItem'];
    const group = this.initScheduleItem(value);
    if (value) {
      const index = this.scheduleList.indexOf(value);
      this.localizedDescriptionSchedule[index] = value.description_localized;
      group.patchValue(value);
    } else {
      this.localizedDescriptionSchedule.push(group.get('description_schedule_localized').value);
    }
    control.push(group);
  }

  deleteSchedule(i: number): void {
    const formArray = <FormArray> this.scheduleGroup.controls['scheduleItem'];
    const id = (<FormGroup> formArray.controls[i]).controls['id'].value;
    const dialogRef = this.confirmDeleteDialog.open(ConfirmationDialogComponent, {
      maxWidth: '500px',
      data: {shouldDelete: true}
    });
    dialogRef.afterClosed().subscribe(shouldDelete => {
      if (shouldDelete) {
        this.activityService.deleteSchedule(this.activityId, id).then(() => {
          formArray.removeAt(i);
          this.scheduleList = this.scheduleList.filter(item => item.id !== id);
          this.localizedDescriptionSchedule.splice(i, 1);
          console.log('Delete schedule success!');
        }, console.log);
      }
    });
  }

  copySchedule(i: number): void {
    const control = <FormArray> this.scheduleGroup.controls['scheduleItem'];
    const values = (<FormGroup> control.controls[i]);
    const group = this.initScheduleItem();
    this.localizedDescriptionSchedule.push(group.get('description_schedule_localized').value);
    control.push(group);
  }

  setDay(i: number, dayI: number): void {
    (<FormGroup> (<FormArray> this.scheduleGroup.controls['scheduleItem']).controls[i]).controls['day'].setValue(dayI);
  }

  submit(): void {
    this.isSubmitted = true;
    if (this.currentCategory && this.scheduleGroup.valid && this.data.image) {
      const newActivity: Activity = {
        companyId: this.companyId,
        name: this.newActivity.get('name_localized').value[DefaultLanguage],
        name_localized: this.localizedName,
        cost: this.newActivity.get('cost').value,
        externalUrl: this.newActivity.get('externalUrl').value.trim(),
        onlineMeetingUrl: this.newActivity.get('onlineMeetingUrl').value.trim(),
        confirmAttendanceUrl: this.newActivity.get('confirmAttendanceUrl').value.trim(),
        description: this.newActivity.get('description_localized').value[DefaultLanguage],
        description_localized: this.localizedDescription,
        categoryId: this.currentCategory.category.id,
        photoUrl: '',
        photo: this.data.image,
        cityId: null,
        facilities: this.getSavedFacilities(),
        amenities: this.getSavedAmenities(),
        genderAvailability: this.selectedGender
      };

      const schedule = this.getSchedule();
      this.activityService.updateActivity(newActivity, schedule).then(_ => {
        this.cancel();
      });
    }
  }

  save(): void {
    this.isSubmitted = true;
    if (this.currentCategory && this.scheduleGroup.valid) {
      const newActivity: Activity = {
        id: this.activityId,
        companyId: this.companyId,
        name: this.newActivity.get('name_localized').value[DefaultLanguage],
        name_localized: this.localizedName,
        description: this.newActivity.get('description_localized').value[DefaultLanguage],
        description_localized: this.localizedDescription,
        cost: this.newActivity.get('cost').value,
        externalUrl: this.newActivity.get('externalUrl').value.trim(),
        onlineMeetingUrl: this.newActivity.get('onlineMeetingUrl').value.trim(),
        confirmAttendanceUrl: this.newActivity.get('confirmAttendanceUrl').value.trim(),
        categoryId: this.currentCategory.category.id,
        photo: null,
        photoUrl: this.imgSrc,
        cityId: null,
        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss').toString(),
        facilities: this.getSavedFacilities(),
        amenities: this.getSavedAmenities(),
        genderAvailability: this.selectedGender
      };

      const schedule = this.getSchedule();
      if (this.newImg && this.data.image) {
        newActivity.photo = this.data.image;
        newActivity.photoUrl = '';
      }

      this.activityService.updateActivity(newActivity, schedule).then(_ => {
        this.cancel();
      });
    }
  }

  getSavedFacilities(): FacilityAmenityItem {
    const savedFacilities = this.facilities.filter(item => {
      return item.enabled;
    }).map((facility: Facility) => {
      return {[facility.id]: true};
    });
    return Object.assign({}, ...savedFacilities);
  }

  getSavedAmenities(): FacilityAmenityItem {
    const savedAmenities = this.amenities.filter(item => {
      return item.enabled;
    }).map((amenity: Amenity) => {
      return {[amenity.id]: true};
    });
    return Object.assign({}, ...savedAmenities);
  }

  localizedNameChanged(name: Localized): void {
    this.localizedName = name;
  }

  localizedDescriptionChanged(description: Localized): void {
    this.localizedDescription = description;
  }

  localizedDescriptionScheduleChanged(description: Localized, index: number): void {
    this.localizedDescriptionSchedule[index] = description;
  }

  cancel(): void {
    this.location.back();
  }

  addCategory(categoryName: string): void {
    if (categoryName) {
      this.activityService.addCategory(categoryName);
    }
  }

  setCategory(category: CategoryFull): void {
    this.currentCategory = category;
  }

  fileChangeListener($event): void {
    const file: File = $event.target.files[0];
    if (!file) {
      return;
    }
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (loadEvent: any) => {
      this.imgSrc = loadEvent.target.result;
      this.data.image = this.imgSrc;
    };
    this.newImg = true;
    myReader.readAsDataURL(file);
  }

  getScheduleItem(): AbstractControl[] {
    return (<FormArray> this.scheduleGroup.get('scheduleItem')).controls;
  }

  copyToClipboard(inputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  selectGender(gender: string): void {
    this.selectedGender = gender;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
