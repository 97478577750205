import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FitKit Studio';

  constructor(private router: Router,
              private authService: AuthService) {
                if (!this.authService.isUserLoggedIn()) {
                  this.router.navigate(['login']);
                  return;
                }
              }
}