<div class="container h-100 w-100">
  <div class="row h-100 w-100 justify-content-center align-items-center">
    <div class="w-25">
      <img src="../../../assets/logo_fitkit_512w.png" class="logo">
      <br>
      <form [formGroup]="userGroup" novalidate>
        <mat-form-field appearance="outline" [style.width.%]="100">
          <mat-label>{{'login.mail' | translate}}</mat-label>
          <input matInput placeholder="{{'login.mail' | translate}}" formControlName="email">
          <mat-error *ngIf="!userGroup.get('email').valid && !userGroup.get('email').hasError('required')">
            {{'login.validaddress' | translate}}
          </mat-error>
          <mat-error *ngIf="userGroup.get('email').hasError('required')">
            {{'login.mailmandatory1' | translate}}<strong>{{'login.mailmandatory2' | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline" [style.width.%]="100">
          <mat-label>{{'login.password' | translate}}</mat-label>
          <input type="password" matInput placeholder="{{'login.password' | translate}}" formControlName="password">
          <mat-error *ngIf="!userGroup.get('password').valid && !userGroup.get('password').hasError('required')">
            {{'login.validpassword' | translate}}
          </mat-error>
          <mat-error *ngIf="userGroup.get('email').hasError('required')">
            {{'login.passwordmandatory1' | translate}}<strong>{{'login.passwordmandatory2' | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <br>
        <mat-checkbox class="text-align-justify"
        formControlName="rememberMe">{{'login.rememberme' | translate}}</mat-checkbox>
        <br><br>
        <button type="submit" class="btn-log-in" (click)="submit()" [disabled]="!userGroup.valid">{{'login.login' | translate}}</button>
        <div *ngIf="userInputError">
          <span class="error">{{'login.failedlogin' | translate}}</span>
        </div>
      </form>
      <div class="d-flex justify-content-between">
        <a class="help-item reset-password" (click)="resetPassword()">{{'login.forgotpassword' | translate}}</a>
      </div>
    </div>
  </div>
</div>