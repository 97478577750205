<h1 mat-dialog-title>{{'forgotpassword.title' | translate}}</h1>
<div mat-dialog-content>
  <p>{{'forgotpassword.enteremail' | translate}}</p>
  <mat-form-field>
    <mat-label>{{'forgotpassword.email' | translate}}</mat-label>
    <input matInput [(ngModel)]="data.email">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">{{'forgotpassword.cancel' | translate}}</button>
  <button mat-button [mat-dialog-close]="data.email" cdkFocusInitial>{{'forgotpassword.resetpassword' | translate}}</button>
</div>