<div class="container-fluid" style="overflow-x: hidden;">
    <div class="row header-div">
        <div class="col positioned-left">
            <img src="../../../assets/images/logo.png" class="fitkit-logo" />
        </div>
        <div class="col positioned-right">
            <p-dropdown class="pdropdown" [options]="supportedLanguages" (onChange)="changeLanguage($event.originalEvent.currentTarget.innerText)"></p-dropdown>
            <mat-icon class="logout-icon" (click)="logout()" style="cursor: pointer;">exit_to_app</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="row">
            <div class="col background-image">
                <img class="image-row" src="../../../assets/images/cover.png" alt="background">
            </div>
        </div>
        <div width="100%" style="position: relative;">  
            <div style="width:15%; position: inherit; float: left; top: -55px; margin-left:3%">
                <img src="{{authService.getPhotoUrl()}}" alt="Avatar" class="avatar">
            </div>
            <div class="container col-lg-12 col-md-8 col-sm-12" style="margin-top: 20px;">
                <div style="float: left;">
                    <p class="h1 lead">{{'profile.hello' | translate}} {{authService.getFirstName()}}!</p>
                    <p class="h3" *ngIf="companies && companies.length == 1">{{'profile.singlestudio' | translate}}</p>
                    <p class="h3" *ngIf="companies && companies.length > 1">{{'profile.morestudios1' | translate}}{{companies.length}}{{'profile.morestudios2' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row card-wraper card-container">
        <div class="col-12 card p-shadow-7" *ngFor="let company of companies">
            <div class="card-body" (click)="onCompanyClick(company)">
                <div class="body">
                    <img *ngIf="company.photoUrl" src="company.photoUrl" class="company-logo" alt="{{'profile.loading' | translate}}" />
                    <div *ngIf="!company.photoUrl">
                        <mat-icon class="company-logo">business</mat-icon>
                    </div>
                    <span *ngIf="company.name" class="mt-2 fw-bold h4 lead">{{company.name}}</span><br>
                    <span *ngIf="company.description" class="h5 fw-lighter">
                        {{company.description}}
                    </span>
                    <div class="mt-2"></div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-nav-list *ngIf="refreshTime[company.id]">
                <a mat-list-item (click)="onCompanyClick(company)">
                    <mat-icon>schedule</mat-icon>
                    <span class="update-info-text">
                          {{'profile.updated' | translate}}{{refreshTime[company.id]}}
                    </span>
                </a>
            </mat-nav-list>
        </div>
    </div>
</div>
