import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivityModel } from 'src/app/data/activity.model';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {DefaultLanguage} from '../../data/languages';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {

  public activityList: ActivityModel[] = [];
  public selectedLanguage: string;

  constructor(public activityService: ActivityService,
              private authService: AuthService,
              private confirmDeleteDialog: MatDialog,
              private router: Router,
              public translateService: TranslateService) { }

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang;
    this.loadData();
    // this.authService.$selectedCompany.subscribe(_ => {
    //   this.activityList = [];
    //   this.loadData();
    // });
  }

  goToDetails(activity: ActivityModel): void {
    this.activityService.setSelectedActivity(activity);
    this.router.navigate(['activity/edit']);
  }

  create(): void {
    this.router.navigate(['activity/create']);
  }

  delete(activity: ActivityModel): void {
    const dialogRef = this.confirmDeleteDialog.open(ConfirmationDialogComponent, {
      maxWidth: '500px',
      data: { shouldDelete: true }
    });
    dialogRef.afterClosed().subscribe(shouldDelete => {
      if (shouldDelete) {
        this.activityService.deleteActivity(activity.activity.id).subscribe(_ => {
          this.activityList = this.activityList.filter(item => {
            return item.activity.id !== activity.activity.id;
          });
        });
      }
    });
  }

  private loadData(): void {
    const company = this.authService.getSelectedCompany();
    this.activityService.getSelectedCompanyActivities(company).subscribe(activities => {
      this.activityList = activities;
    });
  }
}
