<div class="wrapper" style="background-color: #F9FAFC;">
    <div *ngIf="isLoaded && memberList" style="padding: 20px;">
        <div class="row">
            <div class="col row-container">
                <p class="left-description">{{'scan.title' | translate}}</p>
                <!-- <p class="right-button invisible"><button class="close-scanner">Затвори скенер</button></p> -->
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-sm-12">
                <form [formGroup]="codeGroup">
                    <div class="row scan-code-margin">
                        <input type="search" placeholder="Внеси код" class="scan-code col-9" formControlName="code">
                        <button class="scan-submit col-3" (click)="scanCode()" [disabled]="codeGroup.invalid"></button>
                    </div>
                </form>
                <div class="row scan-code-margin">
                    <div class="scan-code-hint">
                        <input type="text" disabled class="code-hint" value="{{'scan.scanhint' | translate}}">
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-sm-12">
                <p-carousel [value]="cards" [numVisible]="2" [numScroll]="2"
                    [ngClass]="!hidePaginator ? 'p-hide-paginator': '' ">
                    <ng-template let-card pTemplate="item">
                        <div class="card" style="margin: 6px;">
                            <div class="row card-body">
                                <div class="col-7">
                                    <p class="h4 mt-0 mb-0 lead fw-bold">{{card.name}}</p>
                                    <p class="h5 lead mt-0 fw-light">{{card.email}}</p>
                                    <p class="h5 lead mt-2 mb-0 fw-light">{{card.activity}}</p>
                                    <p *ngIf="getDateString(card.date) == ''" class="h5 lead mt-0 mb-2 fw-light">
                                        {{card.date}}</p>
                                    <p *ngIf="getDateString(card.date) != ''" class="h5 lead mt-0 mb-2 fw-bold">
                                        {{getDateString(card.date)}}</p>
                                </div>
                                <div class="col-5" *ngIf="card.photoUrl">
                                    <img class="rounded-circle img-fluid" alt="Avatar" src="{{card.photoUrl}}" />
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div *ngIf="!card.isSoon" class="col-12 card-body footer-primary-bg">
                                <div class="row">
                                    <div class="col col-6 card-footer-col">
                                        <p class="mb-0 fw-bold">{{card.code}}</p>
                                    </div>
                                    <div class="col col-6 card-footer-col">
                                        <button *ngIf="!card.approved" class="card-carousel-button approve-button"
                                            (click)="approveCode(card)">{{'scan.approve' | translate}}</button>
                                        <button *ngIf="card.approved" class="card-carousel-button"
                                            (click)="disableCode(card)">{{'scan.cancel' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="card.isSoon" class="col-12 card-body footer-secondary-bg">
                                <div class="row">
                                    <div class="col col-6 card-footer-col">
                                        <p class="mb-0 fw-bold">{{card.code}}</p>
                                    </div>
                                    <div class="col col-6 card-footer-col">
                                        <button *ngIf="!card.approved"
                                            class="card-carousel-button approve-button-secondary"
                                            (click)="approveCode(card)">{{'scan.approve' | translate}}</button>
                                        <button *ngIf="card.approved" class="card-carousel-button"
                                            (click)="disableCode(card)">{{'scan.cancel' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
        <div class="p-shadow-2">
            <p-table #dt1 [value]="memberList" [rows]="10" [scrollable]="true" scrollHeight="flex"
                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="true"
                currentPageReportTemplate="{{'scan.showingusers1' | translate}} {first} - {last} {{'scan.showingusers2' | translate}} {totalRecords}"
                [globalFilterFields]="['user','email','code','activity']" selectionMode="single">
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col style="width:200px">
                        <col style="width:250px">
                        <col style="width:100px">
                        <col style="width:250px">
                        <col style="width:150px">
                        <col style="width:150px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">{{'scan.user' | translate}} <p-sortIcon field="user"></p-sortIcon>
                        </th>
                        <th pSortableColumn="email">{{'scan.email' | translate}} <p-sortIcon field="email"></p-sortIcon>
                        </th>
                        <th pSortableColumn="code">{{'scan.code' | translate}} <p-sortIcon field="code"></p-sortIcon>
                        </th>
                        <th pSortableColumn="activity">{{'scan.activity' | translate}} <p-sortIcon field="activity">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="category">{{'scan.category' | translate}} <p-sortIcon field="category">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="date">{{'scan.date' | translate}} <p-sortIcon field="date"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-member>
                  <ng-container *ngIf="!member.cancelled">
                    <tr *ngIf="member.approved"
                        (click)="selectCode(member)"
                        style="cursor: pointer;">
                        <td>
                            <p-avatar styleClass="p-mr-2"
                                [style]="{'background-color':'#00D4D4', 'color': '#ffffff', 'margin-right': '10px'}"
                                shape="circle">{{member.name[0]}}</p-avatar>{{member.name}}
                        </td>
                        <td>{{member.email}}</td>
                        <td>
                            <p class="mb-0 fw-bold">{{member.code}}</p>
                        </td>
                        <td>{{member.activity}}</td>
                        <td>{{member.category}}</td>
                        <td style="color: gray;">{{member.date}}</td>
                    </tr>
                    <tr *ngIf="!member.approved"
                        style="cursor: not-allowed;">
                        <td>
                            <p-avatar styleClass="p-mr-2"
                                [style]="{'background-color':'#00D4D4', 'color': '#ffffff', 'margin-right': '10px'}"
                                shape="circle">{{member.name[0]}}</p-avatar>{{member.name}}
                        </td>
                        <td>{{member.email}}</td>
                        <td>
                            <p class="mb-0 fw-bold">{{'scan.reserved' | translate}}</p>
                        </td>
                        <td>{{member.activity}}</td>
                        <td>{{member.category}}</td>
                        <td style="color: gray;">{{member.date}}</td>
                    </tr>
                  </ng-container>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div *ngIf="isLoaded && !memberList">
        <h2 class="header-information">{{'scan.nocodes' | translate}}</h2>
    </div>
    <div *ngIf="!isLoaded" class="loading">
        <mat-progress-spinner class="loader" [color]="blue" [mode]="mode">
        </mat-progress-spinner>
    </div>
</div>
