import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Member } from 'src/app/data/member';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent implements OnInit {

  private $members: Subscription;
  public memberList: Member[];
  public cards: Member[];
  public isLoaded = false;
  public codeGroup: FormGroup;
  public selectedCode: string;
  isOpen = false;
  value: Date;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';

  constructor(public companyService: CompanyService,
    public authService: AuthService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar) {
    this.initCodeGroup();
    this.loadData();
    this.authService.$selectedCompany.subscribe(_ => {
      this.initCodeGroup();
      this.isLoaded = false;
      this.cards = [];
      this.memberList = [];
      this.loadData();
    });
  }

  ngOnInit() {
    //Do nothing
    this.cards = [];
  }

  showTabs() {
    this.isOpen = !this.isOpen;
  }

  scanCode() {
    const codeId = (this.codeGroup.value.code as string).toUpperCase();
    const companyId = this.authService.getSelectedCompany().id;
    const $findMember = this.companyService.findMemberWithCode(codeId, companyId).subscribe(member => {
      if (member && !member.cancelled) {
        this.addCard(member);
      } else {
        this.snackBar.open("Кодот е истечен или не постои.", "Во ред", {
          duration: 2000
        });
      }
      $findMember.unsubscribe();
    });
  }

  approveCode(code: Member) {
    this.companyService.approveCode(code.code).then(_ => {
      code.approved = true;
    });
  }

  disableCode(code: Member) {
    this.companyService.disable(code.code).then(_ => {
      code.approved = false;
    });
  }

  selectCode(code: Member) {
    this.codeGroup.get('code').setValue(code.code);
    this.scanCode();
  }

  private loadData() {
    this.$members = this.companyService.getMembers(this.authService.currentProfile)
      .subscribe(members => {
        this.memberList = members;
        // this.initCards();
        this.isLoaded = true;
        this.$members.unsubscribe();
      });
  }

  private addCard(member: Member) {
    this.selectedCode = member.code;
    const previousCards = [];
    previousCards.push(member);
    this.cards.forEach(card => {
      if (card.code != member.code) {
        previousCards.push(card);
      }
    });
    this.cards = previousCards;
  }

  private initCodeGroup() {
    this.codeGroup = this.fb.group({
      code: this.fb.control('', Validators.required)
    });
  }

  getDateString(codeDateString: string): string {
    const today = moment().toDate();
    today.setHours(0, 0, 0, 0);
    const codeDate = moment(codeDateString).toDate();
    const oneDay = 1000 * 60 * 60 * 24;
    const daysDiff = Math.round((codeDate.getTime() - today.getTime()) / oneDay);
    if (daysDiff == 0) {
      return "Денес";
    } else if (daysDiff == -1) {
      return "Вчера";
    } else if (daysDiff == 1) {
      return "Утре";
    }
    return "";
  }
}
