<div class="wrapper">
    <div class="content">
        <div class="p-d-flex" style="display: flow-root;">
            <div class="p-d-flex" style="display: initial; position: relative; text-align: center;">
                <div style="float: left; text-align: center;">
                    <mat-form-field [formGroup]="quickSelectionForm" appearance="fill" style="margin-right: 10px;" [disabled]="isLoading">
                        <mat-label>{{'history.quickselection' | translate}}</mat-label>
                        <mat-select [(ngModel)]="selectedValue" name="quickSelection" formControlName="select" >
                            <mat-option  *ngFor="let item of quickSelectors | translate | keyvalue; let i = index" value="i" (click)="quickSelect(i)">{{item.value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'history.selectdate' | translate}}</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [disabled]="isLoading">
                            <input matStartDate (dateInput)="setStartDate('input', $event)" formControlName="start" placeholder="{{'history.periodstart' | translate}}">
                            <input matEndDate (dateInput)="setEndDate('input', $event)" formControlName="end" placeholder="{{'history.periodend' | translate}}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    
                        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'history.wrongperiodstart' | translate}}</mat-error>
                        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'history.wrongperiodend' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <span class="p-input-icon-left" class="search-span">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="search-input" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="{{'history.searching' | translate}}" />
                </span>
            </div>
            <div style="min-height: 60px; float: right; position: relative; float: right;">
                <p style="position: absolute; top: 50%; right: 10px; white-space: nowrap; color: #3A3B3F;" *ngIf="members">{{'history.numberofusers1' | translate}} {{members.length}} {{'history.numberofusers2' | translate}}</p>
            </div>
        </div>
        <div class="p-shadow-2">
            <p-table #dt1 [value]="members" [rows]="10" [scrollable]="true" scrollHeight="flex" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-customers p-datatable-gridlines"
            [paginator]="true" currentPageReportTemplate="{{'history.showingusers1' | translate}} {first} - {last} {{'history.showingusers2' | translate}} {totalRecords}" [globalFilterFields]="['user','email','code','activity','category']">
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col style="width:200px">
                        <col style="width:250px">
                        <col style="width:100px">
                        <col style="width:250px">
                        <col style="width:150px">
                        <col style="width:150px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">{{'history.user' | translate}} <p-sortIcon field="user"></p-sortIcon></th>
                        <th pSortableColumn="email">{{'history.email' | translate}} <p-sortIcon field="email"></p-sortIcon></th>
                        <th pSortableColumn="code">{{'history.code' | translate}} <p-sortIcon field="code"></p-sortIcon></th>
                        <th pSortableColumn="activity">{{'history.activity' | translate}} <p-sortIcon field="activity"></p-sortIcon></th>
                        <th pSortableColumn="category">{{'history.category' | translate}} <p-sortIcon field="category"></p-sortIcon></th>
                        <th pSortableColumn="date">{{'history.date' | translate}} <p-sortIcon field="date"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-member>
                    <tr>
                        <td><p-avatar styleClass="p-mr-2" [style]="{'background-color':'#00D4D4', 'color': '#ffffff', 'margin-right': '10px'}" shape="circle">{{member.name[0]}}</p-avatar>{{member.name}}</td>
                        <td>{{member.email}}</td>
                        <td>{{member.code}}</td>
                        <td>{{member.activity}}</td>
                        <td>{{member.category}}</td>
                        <td style="color: gray;">{{member.date}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div *ngIf="isLoading" class="loading">
        <mat-progress-spinner
            class="loader"
            [color]="blue"
            [mode]="mode">
        </mat-progress-spinner>
    </div>
</div>