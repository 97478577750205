<div class="wrapper content">
    <div class="row">
        <div class="col-12">
            <button class="create-btn" (click)="create()">{{ 'activity.add' | translate }}</button>
        </div>
    </div>
    <div class="mt-4 p-shadow-2">
        <p-table #dt1 [value]="activityList" [rows]="10" [scrollable]="true" scrollHeight="flex"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
            styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="true"
            currentPageReportTemplate="{{ 'activity.pagination_label' | translate: { first: '{first}', last: '{last}', totalRecords: '{totalRecords}'} }}"
            [globalFilterFields]="['activity','company']"
            selectionMode="single">
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col style="width:200px">
                    <col style="width:250px">
                    <col style="width:100px">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">{{ 'activity.name' | translate }} <p-sortIcon field="activity"></p-sortIcon>
                    </th>
                    <th pSortableColumn="companyName">{{ 'activity.company_name' | translate }} <p-sortIcon field="company"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-activity>
                <tr style="cursor: pointer;">
                    <td (click)="goToDetails(activity)">{{activity.activity.name}}</td>
                    <td (click)="goToDetails(activity)">{{activity.companyName.mk}}</td>
                    <td class="text-danger" (click)="delete(activity)">{{ 'activity.remove' | translate }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
