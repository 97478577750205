import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import * as moment from 'moment';
import { Observable, throwError, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Activity } from 'src/app/data/activity';
import { Category } from 'src/app/data/category';
import { Code } from 'src/app/data/code';
import { Company } from 'src/app/data/company';
import { Member } from 'src/app/data/member';
import { Profile } from 'src/app/data/profile';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private db: AngularFireDatabase) { }

  getMembers(profile: Profile, isHistory = false, startDate: string = null, endDate: string = null): Observable<Member[]> {
    if (isHistory) {
      return this.getMembersWithDate(startDate, endDate, profile, true);
    } else {
      endDate = moment().add(7, 'days').format('YYYY-MM-DD').toString();
      startDate = moment().subtract(7, 'days').format('YYYY-MM-DD').toString();
      return this.getMembersWithDate(startDate, endDate, profile, false);
    }
  }

  private getMembersWithDate(startDate: string, endDate: string, profile: Profile, isHistory: boolean): Observable<Member[]> {
    return new Observable<Member[]>(observer => {
      this.getAllCodeSnapshots(startDate, endDate, profile, isHistory).subscribe(
        (codes: Code[]) => {
          var expectedNumberOfCodes = codes.length;
          if (codes && codes.length > 0) {
            const members: Member[] = [];
            codes.forEach(code => {
              this.getMember(code).subscribe(member => {
                if (member) {
                  member.approved = isHistory ? code.approvedByClient :
                    code.approvedByClient || code.approvedByAdmin;
                  members.push(member);
                  if (members.length == expectedNumberOfCodes) {
                    observer.next(members);
                  }
                } else {
                  expectedNumberOfCodes--;
                }
              })
            });
          } else {
            observer.next(null);
            throwError("no-codes");
          }
        });
    });
  }

  getAllCodeSnapshots(startDate: string, endDate: string,
    profile: Profile = null, isHistory = false,
    company: Company = null): Observable<Code[]> {
    return this.db.list('/code', ref =>
      ref.orderByChild('date')
        .startAt(startDate)
        .endAt(endDate)).snapshotChanges().pipe(
          map(codeActions => {
            const codeSnapshots: Code[] = codeActions.map(c => ({ id: c.payload.key, ...c.payload.val() as Code }));
            const snapshots: Code[] = [];
            codeSnapshots.forEach(codeSnapshot => {
              if (profile) {
                if (codeSnapshot.companyId == profile.selectedCompany.id) {
                  const codeIsApproved = codeSnapshot.approvedByAdmin || codeSnapshot.approvedByClient;
                  if ((isHistory && codeIsApproved) || !isHistory) {
                    snapshots.push(codeSnapshot);
                  }
                }
              } else {
                if (codeSnapshot.companyId == company.id) {
                  const codeIsApproved = codeSnapshot.approvedByAdmin || codeSnapshot.approvedByClient;
                  if ((isHistory && codeIsApproved) || !isHistory) {
                    snapshots.push(codeSnapshot);
                  }
                }
              }
            })
            return snapshots;
          }));
  }

  getAllCodes(startDate: string, endDate: string, profile: Profile): Observable<Code[]> {
    return this.db.list('/code', ref =>
      ref.orderByChild('date')
        .startAt(startDate)
        .endAt(endDate)).valueChanges().pipe(
          switchMap((codes: Code[]) => {
            if (codes) {
              const companyCodes = [];
              codes.forEach(code => {
                if (code.companyId == profile.selectedCompany.id) {
                  companyCodes.push(code);
                }
              })
              return of(companyCodes);
            } else {
              return throwError('invalid-code')
            }
          })
        );
  }

  getCodesFromCompany(company: Company): Observable<Code[]> {
    return this.db.list('/code', ref =>
      ref.orderByChild('companyId').equalTo(company.id))
      .valueChanges().pipe(
        switchMap((codes: Code[]) => {
          if (codes) {
            return of(codes);
          } else {
            return throwError('invalid-code')
          }
        })
      );
  }

  getCompanies(id: string, email: string, authService: AuthService): Observable<Company[]> {
    return authService.getUserRoles(id, email).pipe(
      switchMap(
        roles => {
          return new Observable<Company[]>(observer => {
            const companies: Company[] = [];
            roles.forEach(role => {
              this.getCompany(role.companyId).subscribe(
                company => {
                  const address = company.address[0];
                  if (address) {
                    company.description = address.addressDescription + ", " + address.addressName;
                  }
                  companies.push(company);
                  if (authService.currentProfile) {
                    if (!authService.currentProfile.companyIds) {
                      authService.currentProfile.companyIds = [];
                    }
                    authService.currentProfile.companyIds.push(role.companyId);
                    observer.next(companies);
                  }
                }
              )
            });
          });
        }
      )
    )
  }

  getProfileFromCode(code: Code): Observable<Profile> {
    if (!code.personEmail) {
      return of(null);
    }
    return this.db.list('/profile',
      ref => ref.orderByChild('email')
        .equalTo(code.personEmail)).valueChanges().pipe(
          switchMap((profiles: Profile[]) => {
            if (profiles) {
              return of(profiles[0]);
            } else {
              throwError('no-profile');
            }
          })
        );
  }

  getActivityFromCode(code: Code): Observable<Activity> {
    if (!code.activityName) {
      return of(null);
    }
    return this.db.list('/activity',
      ref => ref.orderByChild('name')
        .equalTo(code.activityName)).valueChanges().pipe(
          switchMap((activities: Activity[]) => {
            if (activities) {
              return of(activities[0]);
            } else {
              throwError('no-activity');
            }
          })
        );
  }

  getCategory(id: string): Observable<Category> {
    return this.db.object('/category/' + id).valueChanges() as Observable<Category>;
  }

  findMemberWithCode(codeId: string, selectedCompanyId: string): Observable<Member> {
    return this.getCode(codeId).pipe(switchMap(code => {
      if (!code || code.companyId != selectedCompanyId) {
        return of(null);
      }
      return this.getMember(code).pipe(map(member => {
        member.code = codeId;
        return member;
      }));
    }));
  }

  approveCode(code: string): Promise<void> {
    return this.db.object('/code/' + code + '/approvedByClient/').set(true);
  }

  disable(code: string) {
    return this.db.object('/code/' + code + '/approvedByClient/').set(false);
  }

  private getCode(id: string): Observable<Code> {
    return this.db.object('/code/' + id).valueChanges() as Observable<Code>;
  }

  getCompany(id: string): Observable<Company> {
    return new Observable<Company>(observer => {
      this.db.object('/company/' + id).snapshotChanges().subscribe(companyAction => {
        const company: Company = {
          id: companyAction.payload.key,
          ...companyAction.payload.val() as Company
        }
        observer.next(company);
      });
    });
  }

  private getMember(code: Code): Observable<Member> {
    return new Observable<Member>(observer => {
      this.getProfileFromCode(code).subscribe(
        (profile: Profile) => {
          if (profile) {
            this.getActivityFromCode(code).subscribe(
              (activity: Activity) => {
                if (activity) {
                  this.getCategory(activity.categoryId).subscribe(
                    (category => {
                      if (category) {
                        const differenceInDays = moment().diff(code.date, 'days');
                        const member: Member = {
                          activity: activity.name,
                          category: category.name,
                          code: code.id,
                          date: code.date,
                          email: profile.email,
                          name: profile.name,
                          photoUrl: profile.photoUrl,
                          approved: code.approvedByClient,
                          isSoon: differenceInDays > -2 && differenceInDays < 2,
                          cancelled: code.cancelled
                        }
                        observer.next(member);
                      } else {
                        observer.next(null);
                        throwError('no-category');
                      }
                    })
                  )
                } else {
                  observer.next(null);
                  throwError('no-activity');
                }
              }
            )
          } else {
            observer.next(null);
            throwError("no-profile")
          }
        }
      );
    });
  }
}
