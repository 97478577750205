import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Company} from 'src/app/data/company';
import {AuthService} from 'src/app/services/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {Languages} from '../../data/languages';
import {formatDate} from '@angular/common';
import {ActivityService} from 'src/app/services/activity/activity.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  public companies: Company[];
  public refreshTime = [];
  supportedLanguages = Languages;

  constructor(public router: Router,
              public authService: AuthService,
              public activityService: ActivityService,
              private translateService: TranslateService) {
    this.translateService.addLangs(this.supportedLanguages);
    this.translateService.setDefaultLang('mk');
    this.translateService.use('mk');
  }

  ngOnInit(): void {
    this.authService.getCompaniesByManager().subscribe(companies => {
      this.companies = companies;
      this.companies.forEach(company => {
        this.activityService.getLastUpdate(company).subscribe(latestDate => {
          if (latestDate) {
            this.refreshTime[company.id] = formatDate(latestDate, 'dd.MM.yyyy во HH:mm', 'en-US');
          }
        });
      });
    });
  }

  changeLanguage(lang: string): void {
    this.translateService.use(lang);
  }

  onCompanyClick(company: Company): void {
    this.authService.selectCompany(company);
    this.router.navigate(['history']);
  }

  logout(): void {
    this.authService.logout(this.router);
  }
}
