import { Injectable } from '@angular/core';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/database';
import {combineLatest, Observable, of} from 'rxjs';
import { map, switchMap, take} from 'rxjs/operators';
import {Activity, FacilityAmenityItem} from 'src/app/data/activity';
import { ActivityModel } from 'src/app/data/activity.model';
import { Category } from 'src/app/data/category';
import { Company } from 'src/app/data/company';
import { Schedule } from 'src/app/data/schedule';
import { AuthService } from '../auth/auth.service';
import { CompanyService } from '../company/company.service';
import { FileService } from '../file-service/file.service';
import {Facility} from '../../data/facility';
import {Amenity} from '../../data/amenity';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private selectedActivity: ActivityModel;
  private CACHED_SELECTED_ACTIVITY = "currentActivity";
  private cachedSelectedActivity: ActivityModel;
  private activityPath: string;

  constructor(private db: AngularFireDatabase,
    private companyService: CompanyService,
    private fileService: FileService) { }

  getAllMemberActivities(authService: AuthService): Observable<ActivityModel[]> {
    const $companies = this.companyService.getCompanies(authService.currentProfile.id, authService.currentProfile.email, authService);
    return $companies.pipe(switchMap(companies => {
      return this.getAllActivities(companies);
    }));
  }

  getAllActivities(companies: Company[]): Observable<ActivityModel[]> {
    return (this.db.list('/activity').valueChanges() as Observable<Activity[]>).pipe(switchMap(activities => {
      const activitiesWithCodes: ActivityModel[] = [];
      activities.map(item => {
        const found = companies.find(company => company.id === item.companyId);
        if (found) {
          const activityModel: ActivityModel = {
            name: item.name_localized,
            companyName: found.name_localized,
            activity: item
          };
          activitiesWithCodes.push(activityModel);
          return { ...item, ...found };
        }
      });
      return of(activitiesWithCodes);
    }));
  }

  getSelectedCompanyActivities(company: Company): Observable<ActivityModel[]> {
    return (this.db.list('/activity', ref =>
      ref.orderByChild('companyId')
        .equalTo(company.id)).snapshotChanges()).pipe(switchMap(activityActions => {
          const activities: Activity[] =
            activityActions.map(a => ({ id: a.payload.key, ...a.payload.val() as Activity }));
          const activityModels: ActivityModel[] = [];
          activities.forEach(activity => {
            const model: ActivityModel = {
              name: activity.name_localized,
              companyName: company.name_localized,
              activity: activity
            }
            activityModels.push(model);
          });
          return of(activityModels);
        }));
  }

  setSelectedActivity(activity: ActivityModel) {
    this.selectedActivity = activity;
    localStorage.setItem(this.CACHED_SELECTED_ACTIVITY, JSON.stringify(activity));
  }

  getSelectedActivity(): ActivityModel {
    if (!this.selectedActivity) {
      this.cachedSelectedActivity = JSON.parse(localStorage.getItem(this.CACHED_SELECTED_ACTIVITY));
      this.selectedActivity = this.cachedSelectedActivity;
    }
    return this.selectedActivity;
  }

  deleteActivity(id: string): Observable<any> {
    return (this.db.object("/activity/" + id).valueChanges() as Observable<Activity>)
      .pipe(take(1),
        switchMap(activity => {
        let cid = activity.companyId;
        let writes = {};
        writes["/activity/" + id] = null;
        writes['/schedule/' + id] = null;
        writes["/company/" + cid + '/activityId/' + id] = null;
        return this.db.object('/').update(writes).then(() => {
          if (activity.photoUrl) {
            return this.fileService.delete(this.activityPath + id);
          }
        });
      }));
  }

  getActivity(id: string): Observable<any> {
    return (this.db.object('/activity/' + id).valueChanges() as Observable<Activity>)
      .pipe(switchMap(activity => {
        return combineLatest([this.db.object('/company/' + activity.companyId).valueChanges(),
        this.db.object('/category/' + activity.categoryId).snapshotChanges()])
          .pipe(map(([company, categoryAction]) => {
            const category = { id: categoryAction.payload.key, ...categoryAction.payload.val() as Category }
            return {
              company: company,
              category: category,
              activity: activity
            }
          }));
      }));
  }

  getActivityPhoto(id: string): Observable<string> {
    return (this.db.object("/activity/" + id).valueChanges() as Observable<Activity>)
      .pipe(switchMap(activity => {
        return of(activity.photoUrl);
      }));
  }

  updateActivity(activity: Activity, schedule: Schedule[] = null): Promise<void> {
    let key = activity.id;
    if (!key) {
      key = this.db.list("/activity").push(null).key;
    }
    let writes = {};
    if (schedule) {
      writes = this.updateSchedule(key, schedule);
    }

    writes['/company/' + activity.companyId + '/activityId/' + key] = true;
    if (activity.photo) {
      return this.fileService.upload(this.activityPath + key, activity.photo).then(downloadUrl => {
        activity.photoUrl = downloadUrl;
        activity.photo = null;
        this.db.object('/activity/' + key).update(activity);
        return this.db.object('/').update(writes);
      });
    } else {
      this.db.object('/activity/' + key).update(activity);
      return this.db.object('/').update(writes);
    }
  }

  getCategoryList() {
    return combineLatest([this.db.list('/category').snapshotChanges(),
    this.db.list('/activity').valueChanges()]).pipe(map(args => {
      let categories = args[0];
      let activities = args[1];
      let categoryFull = [];
      categories.forEach(categoryAction => {
        const category: Category =
        {
          ...categoryAction.payload.val() as Category,
          id: categoryAction.key
        };
        let activityList = activities.filter(
          activity => (activity as Activity).categoryId == category.id);
        categoryFull.push({ category: category, activityNumber: activityList.length });
      })
      return categoryFull;
    }));
  }

  getFacilityList(activity: any): Observable<Facility[]> {
    return this.db.list('/facilities').snapshotChanges().pipe(map(facilities => {
      const facilityList = [];
      let activityFacilities: FacilityAmenityItem = null;
      if (activity) {
        activityFacilities = activity.activity.facilities;
      }
      facilities.forEach(facilityAction => {
        let isFacilityEnabled = false;
        if (activityFacilities) {
          const facilityItem = Object.keys(activityFacilities).find(item => {
            return item === facilityAction.key;
          });
          isFacilityEnabled = facilityItem !== undefined;
        }
        const facility: Facility = {
          ...facilityAction.payload.val() as Facility,
          id: facilityAction.key,
          enabled: isFacilityEnabled
        };
        facilityList.push(facility);
      });
      return facilityList;
    }));
  }

  getAmenityList(activity: any): Observable<Amenity[]> {
    return this.db.list('/amenities').snapshotChanges().pipe(map(amenities => {
      const amenityList = [];
      let activityAmenities: FacilityAmenityItem = null;
      if (activity) {
        activityAmenities = activity.activity.amenities;
      }
      amenities.forEach(amenityAction => {
        let isAmenityEnabled = false;
        if (activityAmenities) {
          const amenityItem = Object.keys(activityAmenities).find(item => {
            return item === amenityAction.key;
          });
          isAmenityEnabled = amenityItem !== undefined;
        }
        const amenity: Amenity = {
          ...amenityAction.payload.val() as Amenity,
          id: amenityAction.key,
          enabled: isAmenityEnabled
        };
        amenityList.push(amenity);
      });
      return amenityList;
    }));
  }


  deleteCategory(category: any) {
    return this.db.object('/category/' + category.key).remove();
  }

  addCategory(categoryName: string) {
    let newCategory = [
      categoryName
    ]
    let n = {};
    newCategory.forEach(i => {
      n[i] = true;
    });
    return this.db.list("/category").push({ name: categoryName });
  }

  getCompanyActivity(id: string): Observable<any[]> {
    return combineLatest(
      [this.db.list('/company/' + id + "/activityId").valueChanges() as Observable<Company[]>,
      this.db.list('/activity').valueChanges() as Observable<Activity[]>])
      .pipe(map(result => {
        let activityIds = result[0];
        let activities = result[1];
        return activities.filter(
          activity => activityIds.find(id => (id as any).$key == (activity as any).$key))
      }));
  }

  getAllCompanyActivities(companyId: string): Observable<Activity[]> {
    return this.db.list("/activity",
      ref => ref.orderByChild('companyId')
        .equalTo(companyId)).valueChanges() as Observable<Activity[]>;
  }

  getLastUpdate(company: Company): Observable<Date> {
    return this.getAllCompanyActivities(company.id).pipe(switchMap(activities => {
      var activityTime: Date = null;
      activities.forEach(activity => {
        if (activity.updatedAt) {
          const updateTime = new Date(activity.updatedAt);
          if (!activityTime) {
            activityTime = updateTime;
          } else if (activityTime.getTime() < updateTime.getTime()) {
            activityTime = updateTime;
          }
        }
      });
      return of(activityTime);
    }));
  }

  getScheduleList(activityId: string): Observable<Schedule[]> {
    return (this.db.list('/schedule/' + activityId)
      .snapshotChanges() as Observable<SnapshotAction<Schedule>[]>)
      .pipe(switchMap(scheduleAction => {
        const schedules: Schedule[] =
          scheduleAction.map(s => ({ ...s.payload.val() as Schedule, id: s.payload.key }));
        return of(schedules);
      }));
  }

  updateSchedule(activityId: string, newSchedule: Schedule[]): Object {
    let writes = {};
    for (let i = 0; i < newSchedule.length; i++) {
      let key = newSchedule[i].id;
      if (!key) {
        key = this.db.list('/schedule/' + activityId).push(null).key;
      }
      writes['/schedule/' + activityId + '/' + key] = newSchedule[i];
    }
    return writes;
  }

  deleteSchedule(activityId: string, id: string): Promise<void> {
    if (!id) {
      Promise.resolve();
    }
    let writes = {};
    writes['/schedule/' + activityId + '/' + id] = null;
    return this.db.object('/').update(writes);
  }

}
